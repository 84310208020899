<template>
  <div
    v-if="Object.keys(creatorData).length"
    id="user-profile"
  >
    <b-card
      class="profile-header mb-2"
      img-src="https://api.myluckyfans.com/api/file/placeholder/cover?color=dark"
      img-top
      alt="cover photo"
      body-class="p-0"
    >
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img
              src="https://api.myluckyfans.com/api/file/placeholder/avatar?color=dark"
              rounded
              fluid
              alt="profile photo"
            />
          </div>
          <!-- profile title -->
          <div class="profile-title ml-3">
            <h2 class="text-white">
              {{ creatorData.username }}
            </h2>
            <p class="text-white">
              {{ creatorData.bio }}
            </p>
          </div>
          <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->

      <!-- profile info  -->
      <section id="profile-info">
        <b-row>
          <b-col
            lg="3"
            cols="12"
            order="2"
            order-lg="1"
          >
            <b-card>
              <!-- about -->
              <div
                v-for="(data,key,index) in aboutData"
                :key="data.title"
                :class="index ? 'mt-2':''"
              >
                <h5 class="text-capitalize mb-75">
                  {{ key }}
                </h5>
                <b-card-text>
                  {{ data }}
                </b-card-text>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>

<script>
import api from '@mlfcore/api'
import router from '@/router'
import {
  BCard, BImg,
} from 'bootstrap-vue'
// import { BRow, BCol } from 'bootstrap-vue'

// import ProfileHeader from './ProfileHeader.vue'
// import ProfileAbout from './ProfileAbout.vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,

    // ProfileHeader,
    // ProfileAbout,
  },
  data() {
    return {
      creatorData: {
        type: Object,
        default: () => {},
      },
      profileData: { },
    }
  },
  created() {
    this.$http.get('/profile/data')
      .then(res => {
        this.profileData = res.data
      });
  },
  mounted() {
    this.initalize()
  },
  methods: {
    initalize() {
      this.getData()
    },
    getData() {
      api.getCreator(`${router.currentRoute.params.id}`, {
        count: 10,
        page: 1,
      }).then(response => {
        /* eslint-disable no-console */
        console.log(response)
        const [res] = response.data
        this.creatorData = res
      }).catch(error => {
        /* eslint-disable no-console */
        console.log(error)
      }).then(() => {
        console.log(this.creatorData.avatar)
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
